import { Html } from '../../Application';
import { horizontalLayout } from '../Atoms/Layout';
import { html } from 'lit';
import { padding } from './Padding';
import Format from '../Atoms/Format';

export default function appFooter(lastUpdate?: Date): Html {
    return html`<small class="color--neutral--light">
        ${padding(
            'large',
            horizontalLayout(
                ['center', 'center'],
                'small',
                intersperse(
                    [
                        html`<span
                            >Copyright&nbsp;${new Date().getFullYear()}</span
                        >`,
                        ...(lastUpdate !== undefined
                            ? [
                                  html`<span>
                                      Laatste update:
                                      ${Format.datetime(lastUpdate)}
                                  </span>`,
                              ]
                            : []),
                        html`<a href="//zakelijk.autotrack.nl" target="_blank">
                            AutoTrack&nbsp;Zakelijk
                        </a>`,
                        html`<a href="mailto:info@autotrack.nl" target="_blank">
                            Contact
                        </a>`,
                    ],
                    html`|`,
                ),
            ),
        )}
    </small>`;
}

function intersperse<T>(arr: T[], sep: T): T[] {
    if (arr.length === 0) {
        return [];
    }

    return arr.slice(1).reduce((xs, x) => xs.concat([sep, x]), [arr[0]]);
}
